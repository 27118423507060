import React from 'react'
import moment, { Moment } from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { v4 as uuidv4 } from 'uuid'

import { editSmartGroupPath } from 'Routes'
import {
  ApplicationRecord,
  CustomField,
  CustomFieldValue,
  CustomFieldValueCapable,
  GranularPermissionTypes,
  Media,
  MediaGroup,
  MediaSubTypes,
  Player,
  Preference,
  Presentation,
  SmartGroup,
} from 'neocast-api-js-client'
import { IndexTableColumn } from 'components/Utils/TableIndexColumn'
import { translateRaw } from 'components/Utils/translateRaw'
import { compact } from 'lodash-es'
import { ValidationErrors, IValidationError } from 'spraypaint/lib-esm/validation-errors'
import { TranslationKey } from 'static-data/TranslationKey'
import { ActionBarActionProps } from 'components/Index/ActionBarActionProps'
import { ActionBarAction } from 'components/Index/ActionBarAction'
import axios from 'axios'
import { IconKey } from 'static-data/IconKey'
import { assertUnreachable } from 'components/Presentations/PresentationEdit/utils'
import { useUserProfile } from 'hooks'
import { useHistory, useLocation } from 'react-router'
import { useCustomFields } from 'components/BackendAPI/BackendQueries'
export { kebabCase } from 'lodash-es'

// setup moment duration formatting
// @ts-ignore
momentDurationFormatSetup(moment)

export const durationSecondsToString = (durationSeconds: number, forceHours = false) => {
  const oneHour = 3600
  const format = durationSeconds > oneHour || forceHours ? 'hh:mm:ss' : 'mm:ss'

  return moment.duration(durationSeconds, 'second').format(format, { trim: false, forceLength: true })
}

export const capitalize = (inputString: string) =>
  inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()

export const dummyIdGenerator = () => uuidv4()

export const durationInWords = (seconds: number) => {
  return moment.duration(seconds, 'seconds').format('y[y] M[mo] d[d] h[h] m[m] s[s]', { trim: 'large mid small' })
}

export const distanceOfTimeInWords = ({
  time,
  startTime = new Date(),
  usePrefixSuffix = false,
}: {
  time: number | string | Date
  startTime?: Date
  usePrefixSuffix?: boolean
}) => {
  if (!time) {
    return
  }

  const delta = new Date(startTime).getTime() - new Date(time).getTime()
  const duration = Math.abs(delta) / 1000.0

  const formattedDuration = moment
    .duration(duration, 'seconds')
    .format('y[y] M[mo] d[d] h[h] m[m] s[s]', { trim: 'large mid small' })

  if (usePrefixSuffix) {
    return delta > 0 ? `${formattedDuration} ago` : `in ${formattedDuration}`
  } else {
    return formattedDuration
  }
}

export const historyMerge = ({
  history,
  pathName,
  paramsToRemove = [],
  query = {},
}: {
  history: { location: { search: string } }
  pathName?: string
  paramsToRemove?: string[]
  query?: object
}) => {
  const urlSearchParams = new URLSearchParams(history.location.search)

  paramsToRemove.forEach(key => urlSearchParams.delete(key))

  Object.entries(query).forEach(([k, v]) => {
    urlSearchParams.set(k, v)
  })

  return { pathName, search: urlSearchParams.toString() }
}

export function addRelativeURLSearchParam({
  relativeURL,
  key,
  value,
}: {
  relativeURL: string
  key: string
  value: string
}) {
  const url = new URL(relativeURL, 'http://example.com')
  url.searchParams.set(key, value)

  return url.pathname + url.search
}

export function arrayUniq<T>(array: T[]) {
  return [...new Set(array)]
}

// Returns a closure of a function that updates the preference and forces a reload
export const saveSortKeyAndDirection = (model: string, preference: Preference, key: string, direction: string) => {
  preference.sortOrders[model] = `${key} ${direction}`
  return preference.save()
}

export const sortKeyAndDirection = (preference: Preference, model: string) => {
  const [sortKey, sortDirection] = (preference.sortOrders[model] || 'name ASC').split(' ')

  return [sortKey, sortDirection]
}

// To determine the effective column keys we need to know all the columns for the current view
// We also need to know the default column keys (if the user has none selected)
// and finally the user column keys
export const effectiveColumnKeys = (
  columns: IndexTableColumn[],
  defaultColumnKeys: string[] | undefined,
  userColumnKeys: string[] | undefined
) => {
  // The column keys to consider are the user column keys (if they have any selected)
  // .. or the default column keys if the user hasn't selected any
  // .. or an empty list if there are neither any user columns or any defaults
  const columnKeys = userColumnKeys || defaultColumnKeys || []
  return effectiveColumns(columns, columnKeys).map(column => column.key())
}

export const effectiveColumns = (columns: IndexTableColumn[], userColumnKeys: string[]) => {
  const dummyColumn: IndexTableColumn = { key: () => '', header: '', render: () => '', fixed: true }

  // Given the user column keys, turn those into the columns themselves and then filter out the fixed
  const userSelectedNonFixedColumns = (userColumnKeys || [])
    .map(key => columns.find(column => key === column.key()) || dummyColumn)
    .filter(column => !column.fixed)

  // Find all the fixed columns
  const fixedColumns = columns.filter(column => column.fixed)

  // The effective column keys are the fixed columns, appended to the user columns and then turned back into keys
  return fixedColumns.concat(userSelectedNonFixedColumns)
}

export const setIfInteger = (value: string, func: (int: number) => any) => {
  if (parseInt(value)) {
    func(parseInt(value))
  }
}

export const getCookie = (name: string) => {
  const CookieRegularExpression = new RegExp(name + '=([^;]+)')
  const value = CookieRegularExpression.exec(document.cookie)

  return value && unescape(value[1])
}

export const ensureAllCustomFieldValuesExists = (
  object: { id?: string; modelClassName: string; customFieldValues: CustomFieldValue[] },
  customFields: CustomField[]
) => {
  if (!customFields || !object || !object.customFieldValues) {
    return
  }

  const usedCustomFieldIds = object.customFieldValues.map(fieldValue => fieldValue.customField.id)
  const unusedCustomFields = customFields.filter(customField => !usedCustomFieldIds.includes(customField.id))

  unusedCustomFields.forEach(customField => {
    const customFieldValue = new CustomFieldValue()
    customFieldValue.modelType = object.modelClassName
    customFieldValue.modelId = object.id
    customFieldValue.customField = customField
    customFieldValue.customFieldId = customField.id
    customFieldValue.value = ''

    object.customFieldValues.push(customFieldValue)
  })
}

export const playerIdToDirectorySlug = (id: number | string) => {
  const slugDirectory = `00${String(Math.floor(Number(id) / 1000))}`.slice(-2)

  return `${slugDirectory}/${`00000${id}`.slice(-5)}`
}

export const objectClassToFontIconClassNames = (objectClass: string | undefined | null): IconKey => {
  switch (objectClass) {
    case 'Network':
    case 'network':
      return 'neocast-icon-network'
    case 'Location':
    case 'location':
      return 'neocast-icon-location'
    case 'Player':
    case 'player':
      return 'neocast-icon-player'
    case 'MediaGroup':
    case 'media-group':
    case 'media_group':
      return 'neocast-icon-media-group'
    case 'PlayerGroup':
    case 'player-group':
    case 'player_group':
      return 'neocast-icon-player-group'
    case 'Media':
    case 'media':
      return 'neocast-icon-media'
    case 'Image':
    case 'image':
      return 'neocast-icon-media-type-image'
    case 'SpotSwap':
    case 'spot_swap':
    case 'spot-swap':
      return 'neocast-icon-media-type-spotswap'
    case 'Audio':
    case 'audio':
      return 'neocast-icon-media-type-audio'
    case 'AudioStream':
    case 'audio_stream':
      return 'neocast-icon-media-type-audiostream'
    case 'Bin':
    case 'bin':
      return 'neocast-icon-media-type-bin'
    case 'Feed':
    case 'feed':
      return 'neocast-icon-media-type-feed'
    case 'FlashMovie':
    case 'flash_movie':
      return 'neocast-icon-media-type-flashmovie'
    case 'HTMLPage':
    case 'html_page':
      return 'neocast-icon-media-type-htmlpage'
    case 'MediaRssFeed':
    case 'media_rss_feed':
      return 'neocast-icon-media-type-mediarssfeed'
    case 'SmartGroup':
    case 'smart_group':
      return 'neocast-icon-media-type-smartgroup'
    case 'Video':
    case 'video':
      return 'neocast-icon-media-type-video'
    default:
      return ''
  }
}

export const objectToLinkPath = ({ object }: { object: { id: string; class: string | undefined | null } }) => {
  switch (object?.class) {
    case 'MediaGroup':
      return `/media-groups/${object.id}`
    case 'Media':
    case 'Image':
      return `/media/${object.id}`
    default:
      return '/'
  }
}

export function serverTimeBlockToClientTimeblockBits(serverTimeblock: string) {
  return serverTimeblock.replaceAll(/(mo|tu|we|th|fr|sa|su|15|:|,)/g, '')
}

export function clientTimeblockBitsToServerTimeblock(clientTimeblockBits: string[]) {
  const DAYS_SYMBOLS = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']

  const bitsAsString = clientTimeblockBits.join('')

  return DAYS_SYMBOLS.map((day, index) => `${day}:15:${bitsAsString.substring(96 * index, 96 * (index + 1))}`).join(',')
}

// TODO: Improve this to define what a timeblock is
export const timeBlockToHtml = (timeblock: string) => {
  return timeBlockToDailyDescriptionArray(timeblock)
    .map((text, index) => `${DAYS_DISPLAY[index]}: ${text}`)
    .join(', ')
}

export const DAYS_DISPLAY = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

export const timeBlockToDailyDescriptionArray = (timeblock: string) => {
  const DAYS_SYMBOLS = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']

  const formatTime = (time: Date) => moment(time).format('LT')

  const dayBitsToHtml = (bitstream: string) => {
    const segments = bitstream.split(/(0)/).filter(match => match.length !== 0)

    if (segments.length === 1) {
      return '24 Hours'
    }

    if (segments.every(segment => segment.length === 0)) {
      return 'No Operating Hours'
    }

    const humanRepresentations: string[] = []

    let currentTimeOffset = new Date('2000-01-01T00:00:00')
    segments.forEach(segment => {
      if (segment === '0') {
        currentTimeOffset = new Date(currentTimeOffset.getTime() + 15 * 60 * 1000)
      } else {
        const timeEnd = new Date(currentTimeOffset.getTime() + 15 * 60 * 1000 * segment.length)

        humanRepresentations.push(`${formatTime(currentTimeOffset)} - ${formatTime(timeEnd)}`)

        currentTimeOffset = timeEnd
      }
    })

    return humanRepresentations.join(', ')
  }

  if (!timeblock || timeblock.length === 0) {
    return ['None', 'None', 'None', 'None', 'None', 'None', 'None']
  }

  return DAYS_DISPLAY.map((_day, index) => {
    const string = `${DAYS_SYMBOLS[index]}:\\d+:([01]+)`
    const expression = new RegExp(string)

    const bitsMatch = expression.exec(timeblock)

    // TODO: What to do if the expression does not match?
    if (!bitsMatch) {
      throw 'Un-parsable Timeblock'
    }

    const bits = bitsMatch[1]

    return dayBitsToHtml(bits)
  })
}

export function replaceElement<ElementType>(array: ElementType[], element: ElementType, replacement: ElementType) {
  const index = array.indexOf(element)

  return [...array.slice(0, index), replacement, ...array.slice(index + 1)]
}

export const timeBlockStringToBitString = (string: string) =>
  string ? string.replace(/(mo|tu|we|th|fr|sa|su|):15:|,/g, '') : '0'.repeat(24 * 4 * 7)

export function invertBitsArray(bits: string[], invert: boolean) {
  return invert ? bits.map(bit => (bit === '1' ? '0' : '1')) : bits
}

export function invertBitsString(bits: string, invert: boolean) {
  return invert ? bits.replace(/[01]/g, bit => (invert ? (bit === '1' ? '0' : '1') : bit)) : bits
}

export const bitsToTimeblockString = (bits: string) => {
  const days = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']
  return days.map((day, index) => `${day}:15:${bits.slice(index * 24 * 4, (index + 1) * 24 * 4)}`).join(',')
}

export function regExpFilter<T>(filter: string, method: (element: T) => string, collection: T[]) {
  return filter ? collection.filter(object => method(object).match(new RegExp(filter, 'i'))) : collection
}

export function intersperse<T>(array: T[], toInsert: T) {
  const emptyArray: T[] = []

  return array.reduce(
    (newArray, element, index) =>
      index < array.length - 1 ? newArray.concat(element, toInsert) : newArray.concat(element),
    emptyArray
  )
}

export const dateToHumanReadable = (dateObjectOrString: Date | string) => {
  try {
    return new Date(dateObjectOrString).toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  } catch (e) {
    return dateObjectOrString
  }
}

export const neocastNumberFormat = (number: number | null | undefined) => number && Intl.NumberFormat().format(number)

export const dateFormatYYYYMMDD = (date: Date) => moment(date).format('YYYY-MM-DD')
export const dateFormatYYYYMMDDinUTC = (date: Date | null) => date?.toISOString().substring(0, 10)
export const neocastDateFormat = (date: Date | Moment | string | null) => moment(date).format('L').replaceAll('/', '-')

export const neocastTimeFormat = (date: Date | Moment | string) => moment(date).format('HH:mm:ss')
export const neocastTwelveHourTimeFormat = (date: Date | Moment | string) => moment(date).format('hh:mm:ss A')

export const neocastDateTimeFormat = (date: Date | Moment | string) => {
  const momentDate = moment(date)

  if (!momentDate.isValid()) {
    return ''
  }

  return momentDate.format('L HH:mm:ss').replaceAll('/', '-')
}

export const classifyMediaPresentations = ({ presentations }: { presentations: Presentation[] }) => {
  const initialState: { active: Presentation[]; inactive: Presentation[] } = {
    active: [],
    inactive: [],
  }

  // If presentations is undefined, return just the initial state
  if (!presentations) {
    return initialState
  }

  return presentations.reduce((classifications, presentation) => {
    // Use moment to ensure we are comparing presentation times correctly
    const now = moment()
    const firstSecondPresentationIsValid = moment(presentation.startDate).startOf('day')
    const lastSecondPresentationIsValid = moment(presentation.endDate).endOf('day')

    const classification =
      firstSecondPresentationIsValid <= now && now <= lastSecondPresentationIsValid ? 'active' : 'inactive'

    return { ...classifications, [classification]: [...classifications[classification], presentation] }
  }, initialState)
}

export const classifyPlayerPresentations = ({
  presentations,
  player,
}: {
  presentations: Presentation[]
  player?: Player
}) => {
  const initialState: { active: Presentation[]; invalid: Presentation[]; inactive: Presentation[] } = {
    active: [],
    invalid: [],
    inactive: [],
  }

  // If presentations is undefined, return just the initial state
  if (!presentations) {
    return initialState
  }

  return presentations.reduce((classifications, presentation) => {
    let classification: 'active' | 'invalid' | 'inactive' = 'invalid'

    // If we don't know the player we'll assume all presentations are at least valid
    // However, if we do have a player we'll ensure the display configurations match
    if (player === undefined || Number(presentation.displayConfigurationId) === Number(player.displayConfigurationId)) {
      // Use moment to ensure we are comparing presentation times correctly
      const now = moment()
      const firstSecondPresentationIsValid = moment(presentation.startDate).startOf('day')
      const lastSecondPresentationIsValid = moment(presentation.endDate).endOf('day')

      classification =
        firstSecondPresentationIsValid <= now && now <= lastSecondPresentationIsValid ? 'active' : 'inactive'
    }

    return { ...classifications, [classification]: [...classifications[classification], presentation] }
  }, initialState)
}

export const targetClassForPresentationOnDemand = (presentationOnDemand: Media | MediaGroup | SmartGroup) => {
  return presentationOnDemand instanceof SmartGroup ? presentationOnDemand.targetClass : undefined
}

export const mediaEditPath = ({ type, id, targetClass }: { type: string; id: string; targetClass?: string }) => {
  switch (type) {
    case 'MediaGroup':
      return `/media-groups/${id}`

    case 'SmartGroup':
      return editSmartGroupPath({
        type: targetClass?.toLowerCase(),
        id: id,
      })

    default:
      return `/media/${id}`
  }
}

// From Modernizr
export const supportsPassiveEventListener = () => {
  var supportsPassiveOption = false
  try {
    var opts = Object.defineProperty({}, 'passive', {
      get: function () {
        supportsPassiveOption = true
        return true
      },
    })
    var noop = function () {}
    window.addEventListener('testPassiveEventSupport', noop, opts)
    window.removeEventListener('testPassiveEventSupport', noop, opts)
  } catch (e) {
    /* Nothing to do */
  }
  return supportsPassiveOption
}

export type ObjectEditAction = { type: 'errors'; errors: object } | { type: 'canUpdate'; canUpdate: boolean }
export type ObjectEditState = { errors: object; editingObject: ApplicationRecord; canUpdate: boolean }

export const objectEditReducer = (state: ObjectEditState, action: ObjectEditAction) => {
  switch (action.type) {
    case 'canUpdate': {
      return {
        ...state,
        canUpdate: action.canUpdate,
      }
    }

    case 'errors': {
      const { errors } = action

      return { ...state, errors }
    }

    default:
      return state
  }
}

export const objectEditInitialState = (state: object) => ({
  ...state,
  canUpdate: true,
  editingObject: undefined,
  errors: undefined,
})

export const rangeArray = (size: number) => [...Array(size).keys()]

export function truncateTextRaw(text: string, { length = 80, ellipsis = '...' }) {
  if (text.length <= length) {
    return text
  }

  return text.slice(0, length) + ellipsis
}

export function truncateText(text: string | undefined | null, { length = 80, ellipsis = <>&hellip;</> }) {
  if (!text) {
    return text
  }

  if (text.length <= length) {
    return <span>{text}</span>
  }

  return (
    <span>
      {text.slice(0, length)}
      {ellipsis}
    </span>
  )
}

// Given an object, recursively collect all the values of a key.
//
// So if the object is:
//
// {
//   "thing": {
//     "something": {
//       "error": "foo",
//       "more": {
//         "error": "bar"
//       }
//     }
//   }
// }
//
// then findValues(object, "error") will be [ "foo", "bar" ]
export function findValues(object: any, key: string) {
  const deCycled = deCycle(object, [])
  return findValuesHelper(deCycled, key, [])
}

// Remove cycles from an object, returning a de-cycled reference copy
//
// This allows us to recursively iterate an object (say from findValues)
// without having to worry about an infinite recursion issue.
function deCycle(obj: any, stack: any): any {
  if (!obj || typeof obj !== 'object') return obj

  if (stack.includes(obj)) return null

  let s = stack.concat([obj])

  return Array.isArray(obj)
    ? obj.map(x => deCycle(x, s))
    : Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, deCycle(v, s)]))
}

function findValuesHelper(object: any, key: string, list: Array<any>): Array<any> {
  if (!object) {
    return list
  }

  if (object instanceof Array) {
    for (var i in object) {
      list = list.concat(findValuesHelper(object[i], key, []))
    }
    return list
  }
  if (object[key]) {
    list.push(object[key])
  }

  if (typeof object == 'object' && object !== null) {
    var children = Object.keys(object)
    if (children.length > 0) {
      for (let i = 0; i < children.length; i++) {
        list = list.concat(findValuesHelper(object[children[i]], key, []))
      }
    }
  }
  return list
}

// From: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/decodeURIComponent
//
// Determines if a string already has encoded elements
export function containsEncodedComponents(stringToTest: string) {
  try {
    // ie ?,=,&,/ etc
    return decodeURI(stringToTest) !== decodeURIComponent(stringToTest)
  } catch (URIError) {
    return false
  }
}

// Async function you can `await` to sleep a specific number of milliseconds
//
// example:
//
// await sleep(2000)
//
export async function sleep(milliseconds: number) {
  return await new Promise(resolve => setTimeout(resolve, milliseconds))
}

const SAFARI_OFFSET_FIX = 1
export const getMonthName = (monthIndex = 0, locale = navigator.language) => {
  const format = new Intl.DateTimeFormat(locale, { month: 'long' })
  const months = []
  for (let month = 0; month < 12; month++) {
    const testDate = new Date(0, month, 1 + SAFARI_OFFSET_FIX, 0, 0, 0)
    months.push(format.format(testDate))
  }
  return months[monthIndex]
}

export function serializeParamsToQueryString(params: any, prefix = '') {
  if (params === null) {
    return ''
  }
  const query: any = Object.keys(params).map(key => {
    const value = params[key]

    if (params.constructor === Array) {
      key = `${prefix}[]`
    } else {
      if (params.constructor === Object) {
        key = prefix ? `${prefix}[${key}]` : key
      }
    }

    if (typeof value === 'object') {
      return serializeParamsToQueryString(value, key)
    } else {
      return `${key}=${encodeURIComponent(value)}`
    }
  })

  return [].concat
    .apply([], query)
    .filter(Boolean)
    .filter(x => x !== '')
    .join('&')
}

export const startOfNextDay = () => {
  const date = new Date()

  date.setDate(new Date().getDate() + 1)
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)

  return date
}

export const tomorrow = () => {
  const date = new Date()
  date.setDate(new Date().getDate() + 1)

  return date
}

export const hourMinuteToHHMM = ({ hour: twentyFourHourBasedHour, minute }: { hour: number; minute: number }) => {
  return `${twentyFourHourBasedHour < 10 ? 0 : null}${twentyFourHourBasedHour}:${minute < 10 ? 0 : null}${minute}`
}

interface Rectangle {
  x: number
  y: number
  width: number
  height: number
}

export function rangeOverlaps(range1: [number, number], range2: [number, number]): boolean {
  // Sort the ranges by their starting values
  const sortedRanges = [range1, range2].sort((a, b) => a[0] - b[0])

  // Check if the end of the first range is greater than or equal to the start of the second range
  return sortedRanges[0][1] >= sortedRanges[1][0]
}

export function doRectanglesOverlap(firstRectangle: Rectangle, secondRectangle: Rectangle): boolean {
  // Check if the two rectangles overlap in the x-axis and y-axis
  return (
    firstRectangle.x < secondRectangle.x + secondRectangle.width &&
    firstRectangle.x + firstRectangle.width > secondRectangle.x &&
    firstRectangle.y < secondRectangle.y + secondRectangle.height &&
    firstRectangle.y + firstRectangle.height > secondRectangle.y
  )
}

export function findLargestFreeRectangle(boundingBox: Rectangle, zones: Rectangle[]) {
  function findFreeSpace(mainRect: Rectangle, subRects: Rectangle[]): Rectangle[] {
    // Start with the main rectangle as the only free space
    let freeSpace: Rectangle[] = [mainRect]

    for (const subRect of subRects) {
      let newFreeSpace: Rectangle[] = []

      for (const space of freeSpace) {
        // Check for intersections between the sub-rectangle and the current free space
        if (doRectanglesOverlap(subRect, space)) {
          // If there is an intersection, split the current free space into multiple smaller spaces
          newFreeSpace.push(...splitRectangle(space, subRect))
        } else {
          // If there is no intersection, the current free space remains unchanged
          newFreeSpace.push(space)
        }
      }

      freeSpace = newFreeSpace
    }

    return freeSpace
  }

  function splitRectangle(rectangleToSplit: Rectangle, subRectangle: Rectangle): Rectangle[] {
    let spaces: Rectangle[] = []

    // Split the current rectangle into up to four smaller spaces, depending on the location of the sub-rectangle
    if (subRectangle.y > rectangleToSplit.y) {
      // Split the space above the sub-rectangle
      spaces.push({
        x: rectangleToSplit.x,
        y: rectangleToSplit.y,
        width: rectangleToSplit.width,
        height: subRectangle.y - rectangleToSplit.y,
      })
    }
    if (subRectangle.x > rectangleToSplit.x) {
      // Split the space to the left of the sub-rectangle
      spaces.push({
        x: rectangleToSplit.x,
        y: rectangleToSplit.y,
        width: subRectangle.x - rectangleToSplit.x,
        height: rectangleToSplit.height,
      })
    }
    if (subRectangle.x + subRectangle.width < rectangleToSplit.x + rectangleToSplit.width) {
      // Split the space to the right of the sub-rectangle
      spaces.push({
        x: subRectangle.x + subRectangle.width,
        y: rectangleToSplit.y,
        width: rectangleToSplit.x + rectangleToSplit.width - (subRectangle.x + subRectangle.width),
        height: rectangleToSplit.height,
      })
    }
    if (subRectangle.y + subRectangle.height < rectangleToSplit.y + rectangleToSplit.height) {
      // Split the space below the sub-rectangle
      spaces.push({
        x: rectangleToSplit.x,
        y: subRectangle.y + subRectangle.height,
        width: rectangleToSplit.width,
        height: rectangleToSplit.y + rectangleToSplit.height - (subRectangle.y + subRectangle.height),
      })
    }

    return spaces
  }

  const freeSpacesSortedByLargestAreaFirst = findFreeSpace(boundingBox, zones).sort(
    (a, b) => b.width * b.height - a.width * a.height
  )

  if (freeSpacesSortedByLargestAreaFirst.length === 0) {
    return null
  }

  return freeSpacesSortedByLargestAreaFirst[0]
}

export function combinations<T>(arr: T[], k: number): T[][] {
  const result: T[][] = []

  function backtrack(start: number, currentCombination: T[]) {
    if (currentCombination.length === k) {
      result.push([...currentCombination])
      return
    }

    for (let i = start; i < arr.length; i++) {
      currentCombination.push(arr[i])
      backtrack(i + 1, currentCombination)
      currentCombination.pop()
    }
  }

  backtrack(0, [])

  return result
}

export function structuredClonePolyfill(obj: any) {
  if (window.structuredClone) {
    return window.structuredClone(obj)
  }

  return JSON.parse(JSON.stringify(obj))
}

export function errorMessagesForObject(updatedObject: { errors: ValidationErrors<ApplicationRecord> }) {
  const errorDetails = findValues(updatedObject, 'errors')
    .filter(error => Object.values(error).length > 0)
    .map(error => Object.values(error))
    .flat() as IValidationError<ApplicationRecord>[]

  if (errorDetails.length > 0) {
    console.debug('The following errors have occurred')
    console.debug(errorDetails)
  }

  const allErrors = errorDetails.map((error: IValidationError<ApplicationRecord> | undefined) => error?.message)
  const uniqueErrors = arrayUniq(allErrors)
  const nonEmptyMessages = compact(uniqueErrors)
  const messages = nonEmptyMessages.map(message => translateRaw(message as TranslationKey))
  return messages
}

export function reducedFraction(numerator: number, denominator: number): [number, number] {
  const gcd = (a: number, b: number): number => {
    if (b === 0) {
      return a
    }
    return gcd(b, a % b)
  }

  const commonDivisor = gcd(numerator, denominator)
  const reducedNumerator = numerator / commonDivisor
  const reducedDenominator = denominator / commonDivisor

  return [reducedNumerator, reducedDenominator]
}

export const anyActiveActions = (props: ActionBarActionProps, actions: ActionBarAction[]) => {
  return actions.some(action => action.enabled && action.enabled(props))
}

export const sortActions = (actions: ActionBarAction[]) => {
  const sorted = [...actions]

  return sorted.sort((a, b) => a.text.localeCompare(b.text))
}

export function padWithSpanOfSpaces(string: string, length: number) {
  if (string.length < length) {
    return (
      <>
        {string}
        {[...Array(length - string.length)].map(() => (
          <>&nbsp;</>
        ))}
      </>
    )
  } else {
    return string
  }
}

export function playbackStyleToTranslation(playbackStyle: string) {
  const playbackStyles: Record<string, TranslationKey> = {
    random_one: 'smart-group-details-random-one',
    locked: 'smart-group-details-locked',
    unlocked: 'smart-group-details-unlocked',
  }

  return playbackStyles[playbackStyle] || 'unknown'
}

export async function recordVisitToObject({ modelClassName, id }: { modelClassName: string; id: string }) {
  await axios.post(`/api/v1/recently_viewed_objects`, {
    model_object_type: modelClassName,
    model_object_id: id,
  })
}

export function objectToErrorMessages({ object }: { object: { errors: ValidationErrors<ApplicationRecord> } }) {
  const errorDetails = findValues(object, 'errors')
    .filter(error => Object.values(error).length > 0)
    .map(error => Object.values(error))
    .flat() as IValidationError<ApplicationRecord>[]

  const allErrors = errorDetails.map((error: IValidationError<ApplicationRecord> | undefined) => error?.message)
  const uniqueErrors = arrayUniq(allErrors)
  const nonEmptyMessages = compact(uniqueErrors)
  const messages = nonEmptyMessages.map(message => translateRaw(message as TranslationKey))

  return messages
}

export function boldMatchingText({ text, search }: { text: string; search: string }) {
  const regex = new RegExp(`(${search})`, 'gi')
  return text.split(regex).map((part, index) => {
    if (regex.test(part)) {
      return <strong key={index}>{part}</strong>
    }
    return part
  })
}

export function granularTypeToIcon(type: GranularPermissionTypes): IconKey {
  switch (type) {
    case 'Network':
      return 'neocast-icon-network'
    case 'Location':
      return 'neocast-icon-location'
    case 'Player':
      return 'neocast-icon-player'
    case 'Media':
      return 'neocast-icon-media'
    default:
      assertUnreachable(type)
  }
}

export function mediaTypeToIcon(type: MediaSubTypes): IconKey {
  switch (type) {
    case 'Audio':
      return 'neocast-icon-media-type-audio'
    case 'AudioStream':
      return 'neocast-icon-media-type-audiostream'
    case 'Bin':
      return 'neocast-icon-media-type-bin'
    case 'Feed':
      return 'neocast-icon-media-type-feed'
    case 'FlashMovie':
      return 'neocast-icon-media-type-flashmovie'
    case 'HTMLPage':
      return 'neocast-icon-media-type-htmlpage'
    case 'Image':
      return 'neocast-icon-media-type-image'
    case 'Media':
      return 'neocast-icon-media-type-media'
    case 'MediaRssFeed':
      return 'neocast-icon-media-type-mediarssfeed'
    case 'SmartGroup':
      return 'neocast-icon-media-type-smartgroup'
    case 'SpotSwap':
      return 'neocast-icon-media-type-spotswap'
    case 'Video':
      return 'neocast-icon-media-type-video'
    default:
      return assertUnreachable(type)
  }
}

export function useIndexPage({
  modelName,
  preferenceKeyName,
  columns,
  defaultColumnKeys,
}: {
  modelName?: string
  preferenceKeyName: string
  columns: IndexTableColumn[]
  defaultColumnKeys?: string[]
}) {
  const { customFields } = useCustomFields(modelName)
  const { preference } = useUserProfile()

  const customColumns = fetchCustomColumnDefinitions(customFields)

  const columnsWithCustom = [...columns, ...customColumns]

  const userSelectedColumnKeys = effectiveColumnKeys(
    columnsWithCustom,
    defaultColumnKeys,
    preference.pageColumns[preferenceKeyName]
  )
  const sortKey = preference.sortKey(preferenceKeyName)
  const sortDirection = preference.sortDirection(preferenceKeyName)

  const selectExtra = arrayUniq(
    columnsWithCustom
      .filter(column => userSelectedColumnKeys.includes(column.key()))
      .flatMap(column => column.selectExtra || [])
  )

  const includes = arrayUniq(
    columnsWithCustom
      .filter(column => userSelectedColumnKeys.includes(column.key()))
      .flatMap(column => column.includes || [])
  )

  return { userSelectedColumnKeys, sortKey, sortDirection, selectExtra, includes, columnsWithCustom }
}

function fetchCustomColumnDefinitions(customFields: CustomField[] | undefined) {
  if (!customFields) {
    return []
  }

  return customFields.map(field => ({
    key: () => `.custom_field_${field.key()}`,
    headerTranslated: field.name,
    render: function (object: CustomFieldValueCapable) {
      const customFieldValue = object.customFieldValues.find(
        customFieldValue => String(customFieldValue.customFieldId) === field.key()
      )

      if (!customFieldValue || customFieldValue.enabled === false) {
        return <></>
      }

      switch (field.dataType) {
        case 'String':
          return customFieldValue?.value
        case 'Boolean':
          return renderBooleanCustomFieldValue(customFieldValue?.value)
        case 'Numeric':
          return customFieldValue?.value
        case 'Date':
          return customFieldValue?.value
        default:
          return <></>
      }
    },
    includes: 'custom_field_values',
    selectExtra: [],
  }))
}

export function useNavigation() {
  const history = useHistory()
  const { search } = useLocation()

  function push({ url }: { url: string }) {
    const fullUrl = new URL(url, 'http://example.com')

    history.push(fullUrl.pathname)
  }

  function pushAndRetainSearch({ url }: { url: string }) {
    const fullUrl = new URL(url, 'http://example.com')
    fullUrl.search = search

    history.push(fullUrl.pathname + fullUrl.search)
  }

  return { push, pushAndRetainSearch }
}

export function encodeURIComponentWithUndefined(value: string | number | boolean | undefined) {
  return value === undefined ? undefined : encodeURIComponent(value)
}

export function displayConfigurationToResolutionCategory(displayConfiguration: { screenResolutionX: number }) {
  if (displayConfiguration.screenResolutionX >= 3128) {
    return 'UHD'
  }

  if (displayConfiguration.screenResolutionX >= 1024 && displayConfiguration.screenResolutionX < 3128) {
    return 'HD'
  }

  if (displayConfiguration.screenResolutionX < 1024) {
    return 'SD'
  }

  return 'Unknown'
}

export function isValidFloatForCustomFieldValues(value: string | undefined) {
  if (value === undefined) {
    return true
  }

  // NOTE: Make sure this validation matches the same one used
  //       on the server side when validating CustomFieldValue
  return /^^[-+]?\d*\.?\d*(?:[eE][-+]?\d+)?$/.test(value)
}

export function headerTextFromIndexTableColumn(column: IndexTableColumn) {
  if ('header' in column) {
    return translateRaw(column.header)
  } else {
    return column.headerTranslated
  }
}

export function renderBooleanCustomFieldValue(value: string) {
  // Anything other than true or false is considered empty
  if (value !== 'true' && value !== 'false') {
    return null
  }

  return translateRaw(value)
}

export function sortByNameCaseInsensitive<T extends { name: string }>(collection: T[]): T[] {
  return collection.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
}

export function prefixPad({
  value,
  length,
  prefix = '0',
}: {
  value: number | string
  length: number
  prefix?: string
}) {
  if (value === undefined || value === null) {
    return value
  }

  return value.toString().padStart(length, prefix)
}

export function yearMonthDayHourMinuteToReadableDateTime({
  year,
  month,
  day,
  hour,
  minute,
}: {
  year: number | undefined | null
  month: number | undefined | null
  day: number | undefined | null
  hour: number | undefined | null
  minute: number | undefined | null
}) {
  const readableDateTime =
    year !== undefined &&
    year !== null &&
    month !== undefined &&
    month !== null &&
    day !== undefined &&
    day !== null &&
    hour !== undefined &&
    hour !== null &&
    minute !== undefined &&
    minute !== null
      ? `${prefixPad({ value: year, length: 4 })}-${prefixPad({ value: month, length: 2 })}-${prefixPad({
          value: day,
          length: 2,
        })} ${prefixPad({ value: hour > 12 ? hour - 12 : hour, length: 2 })}:${prefixPad({
          value: minute,
          length: 2,
        })} ${hour > 12 ? translateRaw('pm') : translateRaw('am')}`
      : undefined

  return readableDateTime
}
